import { title } from 'process';
import { EnumCompanyRole, EnumRoleUser } from '../common/enums/enum-role';

export const QuestionStatus = {
  Publish: 1, //: Xuất bản
  Draft: 2, //: Nháp
};

export const QuestionStatusLabel = ['Xuất bản', 'Nháp'];

export const ExamSetType = {
  ManualExam: 1, //: Đề thi nhập thủ công
  BankQuestionExam: 2, //: Đề thi từ danh sách ngân hàng
  ComputerExam: 3, //: Đề thi từ máy tính
  WordExam: 4, //: Đề thi từ file Word
  PDFExam: 5, //: Đề thi từ file PDF
  ExcelExam: 6, //: Đề thi từ file PDF
};

export const ExamSetTypeLabel = [
  'Đề thi nhập thủ công',
  'Đề thi từ danh sách ngân hàng',
  'Đề thi từ máy tính',
  'Đề thi từ file Word',
  'Đề thi từ file PDF',
  'Đề thi từ file Excel',
];

export const QuestionType = {
  TrueFalseQuestion: 1, //: Câu hỏi đúng sai
  OneSelectQuestion: 2, //: Một lựa chọn
  MultiSelectQuestion: 3, //: Nhiều lựa chọn
  GroupQuestion: 4, //: Câu nhóm
  CombineAnswerQuestion: 5, //: Ghép đáp án
  FillTheBlankQuestion: 6, //: Điền từ
  SortQuestion: 7, //: Sắp xếp
  EssayQuestion: 8, //: Tự luận
};

export const QuestionTypeLabel = {
  1: 'Đúng - Sai',
  2: 'Một lựa chọn',
  3: 'Nhiều lựa chọn',
  4: 'Câu hỏi nhóm',
  8: 'Tự luận',
};

export const colorLevel = [
  'blue',
  'green',
  'yellow',
  'orange',
  'red',
  'pink',
  'purple',
  'gray',
  'black',
  'brown',
  'silver',
];

export function getKeys(obj: any): Array<string> {
  return Object.keys(obj);
}

export function removeEmpty(obj) {
  return Object.fromEntries(
    Object.entries(obj).filter(
      ([key, value]) => value !== null && value !== '' && value !== undefined,
    ),
  );
}

export const typeOpenPage = ['Trang hiện tại', 'Trang mới'];

export const typeShowMenu = [
  'Luôn hiển thị',
  'Hiển thị khi chưa đăng nhập',
  'Đăng nhập',
  'Không hiển thị',
  'Chỉ hiển thị đối với giảng viên',
  'Chỉ hiển thị đối với học viên',
];

export const measurementD = 'G-2L03RB2KC7';
